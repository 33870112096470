


export default {
    tj51la: "KQ6pHrm6xY3DNXnx",
    dowon: "download-en",
    qidai: "Please stay tuned",
    // 公共
    login: 'Log In',
    register: 'Register',

    home: 'Home',
    user_center: 'User Center',
    money: "Balance",
    pay: 'Payment',
    user: 'User',
    login_register: "Log In/Register",

    //登錄 註冊
    login_welcom: 'Welcome to Plus18 Games',
    user_name: 'Username',
    user_name_placeholder: 'Please enter a username: 6-20 characters',
    pwd: 'Password',
    pwd_placeholder: 'Please enter a password: 8~16 characters',
    re_pwd: 'Confirm Password',
    re_pwd_placeholder: 'Please re-enter your password: 8~16 characters',
    no_account: 'No account?',
    had_account: 'Already have an account?',

    // 個人中心
    currency: "USD",
    go_recharge: "Recharge",
    in_game_recharge: 'Please top up in-game',
    recharge_log: "Recharge Records",
    consume_log: "Consume Records",
    setting: 'Settings',

    //設置中心
    setting_center: "Settings Center",
    change_pwd: "Change Password",
    old_pwd: "Old Password",
    old_pwd_placeholder: "Please enter the old password",
    new_pwd: "New Password",
    new_pwd_placeholder: "Please enter a new password: 8-16 characters",
    re_new_pwd: "Re-enter New Password",
    re_new_pwd_placeholder: "Please enter the password again: 8-16 characters",
    confirm_change: "Confirm Changes",

    ht_recharge: "Confirm Changes",
    recharge: "System Top-up",
    buy_prop: "User Top-up",
    bind_phone: "Purchase Items",
    loading_more: "Mobile Binding Bonus",
    no_data: "Load More",

    //tip
    user_name_tip: "Please fill in the username",    
    pwd_tip: "Please fill in the password",          
    pwd_tip_fail: "Password must be 6-20 characters",
    re_pwd_err: "Passwords do not match",            
    login_success: "Login successful",               
    register_success: "Registration successful",     
    change_pwd_success: "Password change successful",
    loading: "Loading",                              
    please_login: "Please login",       
    qr_bg:"qr_bg_en",             
    jjsxpng: "jjsx-en",

    more_game: 'More',
    jjsx: 'Coming Soon',
    game_list: 'Games',
    qy: 'Benefits',
    notice_title: 'Announcements',
    down_game: 'Download',

    t1: 'Are you 18 years old or older?',
    t2: 'You are about to enter a restricted website containing sexual, violent, and horror content. Please confirm once again that you are of legal age and wish to continue browsing. By clicking "Yes" to enter the website, you confirm that you are 18 years old or have reached the age of majority in your region, and you agree to abide by the terms.',
    t3: 'Terms of Service',
    t4: 'Privacy Agreement',
    t5: 'Yes, I am 18 or older',
    t6: 'Under 18, please exit',
}