
import { useTranslation } from "react-i18next"
import useAPI from "../../hooks/common/useAPI"
import "./index.less"
const Tip18 = ({ visible, onClose }) => {

    const{t} = useTranslation()
    const {setItemWithExpiry} = useAPI()
    const closeTip = ()=>{
        onClose?.()
        document.body.classList.remove('no-scroll');
        // window.sessionStorage.setItem('tan', 1)
        setItemWithExpiry('is_show_top', 1)
    }

    const goBack = ()=>{
        window.history.back()
    }


    return visible && <div className="big-b">
        <div style={{ backgroundColor: "#1d2630", borderRadius: "0.1rem", padding: "0.2rem 0.2rem 0.4rem 0.2rem", }}>
            <div style={{display:"flex", alignItems:"center"}}>
                <img src={require('../../images/img/logo.png')} style={{ width: "0.4rem", height:"0.4rem", marginRight:"0.08rem" }} alt="" />
                <div style={{fontSize:"0.18rem", fontWeight:"bold", color:"#FCFCFD",}}>Plus18 Games</div>
            </div>

            <div style={{textAlign: "center", width: "2.8rem" }}>
                <div style={{ fontSize: "0.16rem", color: "#fff", fontWeight: "bold", margin: "0.2rem 0 0.1rem 0" }}>{t('t1')}</div>
                <div style={{ color: "#9a9fa5", lineHeight: "0.26rem", fontSize: "0.12rem" }}>
                    {t('t2')}
                    <a href="#/services"  rel="noreferrer noopenner" className="moreLink">{t('t3')}</a>、
                    <a href="#/privacy"  rel="noreferrer noopenner" className="moreLink">{t('t4')}</a>
                </div>
                <div style={{display:"flex", justifyContent:"center", gap:"0.5rem", marginTop:"0.2rem"}}>
                    {/* <div style={{padding:"0.1rem 0.4rem", border:"#fff 1px solid", borderRadius:"0.5rem", color:"#fff", fontWeight:"bold", fontSize:"0.14rem"}} onClick={()=>goBack()}>否</div> */}
                    <div style={{padding:"0.1rem 0.4rem",  borderRadius:"0.5rem", color:"#fff", fontWeight:"bold", fontSize:"0.14rem", backgroundColor:"#3871fe", cursor:"pointer"}} onClick={()=>closeTip()}>{t('t5')}</div>
                </div>
                <div onClick={()=>goBack()} style={{color:"#9a9fa5", fontSize:"0.12rem" , marginTop:"0.15rem" , cursor:"pointer"}}>{t('t6')}</div>
            </div>

        </div>
    </div>
}

export default Tip18